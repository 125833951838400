import React from 'react'

import SEO from 'components/SEO'
import Hero from 'elements/Hero'
import FeaturedNews from '../elements/FeaturedNews'

const Home = () => {
  return (
    <>
      <SEO title="Home" />
      <Hero />
      <FeaturedNews />
    </>
  )
}

export default Home
