import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

import ZSEM from 'images/zsem.jpg'
import { GatsbyImage } from 'gatsby-plugin-image'
import { formatDate } from 'utils'

const FeaturedNews = () => {
  const truncateString = (text, charLength = 150) => {
    if (text.length > charLength) {
      return text.substring(0, charLength) + '...'
    } else {
      return text
    }
  }

  const {
    allMarkdownRemark: { edges: featuredNews }
  } = useStaticQuery(query)

  return (
    <section className="py-24">
      <div className="container mx-auto px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 place-items-center mb-14">
          {featuredNews.length &&
            featuredNews.map(
              ({
                node: {
                  id,
                  excerpt,
                  fields: { slug },
                  frontmatter: { title, date, images }
                }
              }) => {
                return (
                  <Link
                    key={`${id}-${slug}`}
                    to={`/news/${slug}`}
                    className="block w-full bg-white mx-1 max-w-300px shadow-news-card rounded-lg overflow-hidden cursor-pointer  border-2 border-transparent hover:border-vividBlue transition ease-in-out duration-300"
                  >
                    <div className="h-170px overflow-hidden">
                      {images[0] !== null ? (
                        <GatsbyImage
                          className="rounded-t-lg object-cover w-full h-full fix-position"
                          image={images[0].childImageSharp.gatsbyImageData}
                          alt={title}
                          quality="100"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="flex flex-col p-4 lg:p-6 h-352px">
                      <span className="text-xs text-black mb-2">{formatDate(date, 'en-us')}</span>
                      <h3 className="text-base text-vividBlue font-bold leading-tight mb-5">{truncateString(title)}</h3>
                      {/* <p className="text-sm">{excerpt}</p> */}
                      <div className="bg-white h-10 w-10 block"></div>
                    </div>
                  </Link>
                )
              }
            )}
        </div>
        <div className="text-center">
          <Link
            className="inline-flex self-center text-white bg-vividBlue text-sm px-5 py-3 rounded-3xl transform transition duration-300 ease-in-out hover:-translate-y-1"
            to="/news"
          >
            Read more news
          </Link>
        </div>
      </div>
    </section>
  )
}

export default FeaturedNews

const query = graphql`
  query FeaturedNews {
    allMarkdownRemark(
      filter: { frontmatter: { news: { eq: true } } }
      limit: 4
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            images {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          excerpt
        }
      }
    }
  }
`
