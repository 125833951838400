import React from 'react'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import { Link, navigate } from 'gatsby'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'styles/hero-slider.css'

const Hero = () => {
  const {
    rawData: { edges: slides }
  } = useStaticQuery(query)

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <div className="container mx-auto px-8">
      <div className="flex justify-center my-36">
        <StaticImage
          className="max-w-982px"
          src="../../images/croasia-hero.png"
          alt="Croasia - ZSEM Croasia Institute"
          placeholder="hidden"
          quality="100"
        />
      </div>

      <Slider {...settings} className="hero-slider pt-16 md:pt-40">
        {slides.length &&
          slides.map(
            ({
              node: {
                id,
                title,
                subtitle,
                link,
                linkText,
                src: {
                  childImageSharp: { gatsbyImageData }
                }
              }
            }) => {
              return (
                <div className="outline-none" key={id}>
                  <div className="flex justify-between">
                    <div className="flex flex-col justify-center w-full md:w-1/2 px-8">
                      <h1 className="text-4xl lg:text-5xl text-black font-bold">{title}</h1>
                      <p className="text-sm text-black mt-3 mb-9">{subtitle}</p>
                      <Link
                        className="inline-flex self-start text-white bg-vividBlue text-sm px-5 py-3 rounded-3xl transform transition duration-300 ease-in-out hover:-translate-y-1"
                        to={link}
                      >
                        {linkText}
                      </Link>
                    </div>

                    <div className="hidden md:flex w-full max-w-690px h-400px rounded-lg overflow-hidden">
                      <GatsbyImage
                        className="object-cover w-full h-full rounded-lg"
                        image={gatsbyImageData}
                        alt={title}
                        placeholder="blurred"
                        loading="eager"
                        quality="100"
                      />
                    </div>
                  </div>
                </div>
              )
            }
          )}
      </Slider>
      <hr style={{ height: '1.5px' }} className="mt-10 bg-vividBlue opacity-50" />
    </div>
  )
}

export default Hero

const query = graphql`
  query useSlides {
    rawData: allSlidesJson {
      edges {
        node {
          id
          title
          subtitle
          link
          linkText
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
