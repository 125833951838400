export const formatDate = (dateString, locale) => {
  let date = new Date(dateString)

  const domesticDate = new Intl.DateTimeFormat('hr-HR', { year: 'numeric', month: 'long', day: 'numeric' })
  const foreignDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' })

  if (locale !== 'hr') {
    return foreignDate.format(date)
  }
  return domesticDate.format(date)
}
